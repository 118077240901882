import { getEnvironmentName } from './getEnvironmentName';

import type { DeployedEnvConfig } from '@tonkean/env';
import { loadEnvConfig } from '@tonkean/env/loadEnvConfig';

async function getDeployedEnvConfig(): Promise<DeployedEnvConfig> {
    const deployedEnvConfigResponse = await fetch('./deployed-env-config.json');
    const deployedEnvConfigResponseJson = await deployedEnvConfigResponse.json();
    return deployedEnvConfigResponseJson as DeployedEnvConfig;
}

async function loadAppConfig(): Promise<void> {
    const deployedEnvConfig = await getDeployedEnvConfig();
    const environmentName = getEnvironmentName(deployedEnvConfig);

    console.log(`Loading environment config for ${environmentName}`);

    const config = await loadEnvConfig(environmentName);

    (typeof globalThis !== 'undefined' ? globalThis : window).ENV_CONFIG = config;
    (typeof globalThis !== 'undefined' ? globalThis : window).DEPLOYED_ENV_CONFIG = deployedEnvConfig;
}

export { loadAppConfig };
