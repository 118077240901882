import type { DeployedEnvConfig } from '@tonkean/env';

const DEFAULT_ENV = 'local';

function getEnvironmentName(deployedEnvConfig: DeployedEnvConfig | undefined = undefined): string {
    // get environment from env var
    if (import.meta.env.VITE_TONKEAN_ENV) {
        return import.meta.env.VITE_TONKEAN_ENV;
    }

    return deployedEnvConfig?.defaultEnvironment || DEFAULT_ENV;
}

export { getEnvironmentName };
